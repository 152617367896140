"use client";
import { useEffect, useRef, WheelEvent, TouchEvent } from "react";
import { gsap } from "gsap";
import React from "react";
import {
  NewestAnimation,
  ServicesAnimation,
  FooterAnimation,
} from "@/animation/SectionAnimations";

interface PagesProps {
  children: JSX.Element[];
}

export default function AnimationScrollZara({
  children,
}: PagesProps): Promise<JSX.Element> {
  const slidesRef = useRef<HTMLDivElement[]>([]);
  const totalSlides = children.length;
  const animatingRef = useRef<boolean>(false);
  const touchStartYRef = useRef<number>(0);
  const touchLastYRef = useRef<number>(0);

  useEffect(() => {
    slidesRef.current = Array.from(document.querySelectorAll(".slide"));

    // Aplicar transformación inicial a los slides
    slidesRef.current.forEach((slide, index) => {
      gsap.set(slide, { y: `${index * 100}%` });
    });

    let currentIndex = 0;

    function animateTransitionDown(): void {
      if (animatingRef.current || currentIndex === totalSlides - 1) return;
      animatingRef.current = true;

      const currentSlide = slidesRef.current[currentIndex];
      const nextIndex = (currentIndex + 1) % totalSlides;
      const nextSlide = slidesRef.current[nextIndex];

      gsap.to(currentSlide, {
        y: "-100%",
        duration: 0.4,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set(currentSlide, { y: "100%" });
        },
      });

      gsap.fromTo(
        nextSlide,
        { y: "100%", opacity: 0 },
        {
          y: "0%",
          opacity: 1,
          duration: 0.4,
          ease: "power2.inOut",
          onComplete: () => {
            animatingRef.current = false;
            updateHeader(nextSlide);
            currentIndex = nextIndex;
          },
        }
      );
    }

    function animateTransitionUp(): void {
      if (animatingRef.current || currentIndex === 0) return;
      animatingRef.current = true;

      const currentSlide = slidesRef.current[currentIndex];
      const prevIndex = (currentIndex - 1 + totalSlides) % totalSlides;
      const prevSlide = slidesRef.current[prevIndex];

      gsap.to(currentSlide, {
        y: "100%",
        duration: 0.4,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set(currentSlide, { y: "-100%" });
        },
      });

      gsap.fromTo(
        prevSlide,
        { y: "-100%", opacity: 0 },
        {
          y: "0%",
          opacity: 1,
          duration: 0.4,
          ease: "power2.inOut",
          onComplete: () => {
            animatingRef.current = false;
            updateHeader(prevSlide);
            currentIndex = prevIndex;
          },
        }
      );
    }

    function handleScroll(event: WheelEvent<HTMLDivElement>): void {
      if (event.deltaY > 0) {
        animateTransitionDown();
      } else {
        animateTransitionUp();
      }
    }

    function handleTouchStart(event: TouchEvent<HTMLDivElement>): void {
      touchStartYRef.current = event.touches[0].clientY;
      touchLastYRef.current = touchStartYRef.current;
    }

    function handleTouchMove(event: TouchEvent<HTMLDivElement>): void {
      const touchEndY = event.touches[0].clientY;
      const deltaY = touchEndY - touchLastYRef.current;
      touchLastYRef.current = touchEndY;

      const currentSlide = slidesRef.current[currentIndex];
      const currentY =
        parseFloat(currentSlide.style.getPropertyValue("y")) || 0;
      const newY = Math.min(
        Math.max(currentY + deltaY, -(totalSlides - 1) * 100),
        0
      );

      gsap.set(currentSlide, { y: `${newY}%` });
    }

    function handleTouchEnd(event: TouchEvent<HTMLDivElement>): void {
      const touchEndY = event.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartYRef.current;

      if (deltaY > 50) {
        animateTransitionUp();
      } else if (deltaY < -50) {
        animateTransitionDown();
      }
    }

    function updateHeader(slide: HTMLDivElement) {
      const headerH2 = document.querySelector(".header__logo h2");
      const slideTitle = slide.getAttribute("data-title");
      const header = document.querySelector(".header");
      if (headerH2 && header) {
        if (slideTitle) {
          header.setAttribute("data-current-slide", slideTitle);
          gsap.to(headerH2, {
            opacity: 0,
            duration: -0.5,
            onComplete: () => {
              headerH2.textContent = slideTitle;
              gsap.to(headerH2, { y: "0%", opacity: 1, duration: -0.5 });
            },
          });
        } else {
          header.removeAttribute("data-current-slide");
          gsap.to(headerH2, { y: "100%", opacity: 0, duration: -0.5 });
        }
      }
    }

    function detectScroll() {
      let ctx = gsap.context(() => {}, slidesRef);
      NewestAnimation();
      ServicesAnimation();
      FooterAnimation();
      return () => ctx.revert();
    }

    window.addEventListener("wheel", handleScroll as any);
    window.addEventListener("touchstart", handleTouchStart as any);
    window.addEventListener("touchmove", handleTouchMove as any);
    window.addEventListener("touchend", handleTouchEnd as any);

    return () => {
      window.removeEventListener("wheel", handleScroll as any);
      window.removeEventListener("touchstart", handleTouchStart as any);
      window.removeEventListener("touchmove", handleTouchMove as any);
      window.removeEventListener("touchend", handleTouchEnd as any);
      detectScroll();
    };
  }, [children, totalSlides]);

  return (
    <div className="container__scroll">
      {React.Children.map(children, (child, index) => (
        <>{child}</>
      ))}
    </div>
  ) as any;
}

"use client";
import { useMediaQuery } from "usehooks-ts";

export default function VideoPlayer() {
  const matches = useMediaQuery("(min-width: 768px)");

  return (
    <>
      {matches ? (
        <video
          loop
          autoPlay
          muted
          src="https://res.cloudinary.com/dxwdtbjkq/video/upload/f_auto:video,q_auto/r8rkuyfvx8prxiacakyk"
        ></video>
      ) : (
        <video
          loop
          autoPlay
          muted
          src="https://res.cloudinary.com/dxwdtbjkq/video/upload/f_auto:video,q_auto/reisi8idtn79nc1laqi2"
        ></video>
      )}
    </>
  );
}
